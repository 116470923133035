import React from "react"

import Header from "../components/header"
import Footer from "../components/footer"

import SEO from "../components/seo"

import Stuckbildwerk1 from "../components/stuckbildwerk1"
import Stuckbildwerk2 from "../components/stuckbildwerk2"
import Stuckbildwerk3 from "../components/stuckbildwerk3"
import Stuckbildwerk4 from "../components/stuckbildwerk4"

import "./index.css"

export default function StuckbildwerkePage() {
  return (
    <>
      <SEO
        description="restaura ratisbona, Ihr Team für Restaurierungen. Stuckbildwerke"
        title="restaura ratisbona, Ihr Team für Restaurierungen. Stuckbildwerke"
        lang="de"
      />
      <Header />
      <section>
        <h1 className="indexHeadline">Stuckbildwerke</h1>
        <h2 className="referenceH2">
          Baldachinaltar (1773 - 1779), Augustinerkirche, Mainz, Rheinland-Pfalz
        </h2>
        <h3 className="referenceH3">
          Konservierung, Polierweißfassung, Vergoldungen, Lasuren auf Blattgold
        </h3>
        <div className="referencePageDiv">
          <div className="referenceImages">
            <label htmlFor="Stuckbildwerk1">
              <Stuckbildwerk1 id="Stuckbildwerk1" />
              Baldachinaltar im Endzustand
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Stuckbildwerk2">
              <Stuckbildwerk2 id="Stuckbildwerk2" />
              Kreuzabnahme in der Mittelnische: vorgefundener Zustand mit
              Überarbeitungen von 1956. Als Bindemittel wurden Alkydharzlack
              anstelle der ursprünglichen Polierweißfassung und Bronze statt
              einer Polimentblattvergoldung nachgewiesen
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Stuckbildwerk3">
              <Stuckbildwerk3 id="Stuckbildwerk3" />
              Gesamtaufnahme der Kreuzabnahmegruppe nach der Rekonstruktion
              aufgrund der Befundlage
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Stuckbildwerk4">
              <Stuckbildwerk4 id="Stuckbildwerk4" />
              Endzustand der Vergoldung mit roter Lasur auf der Basis von
              Analysen und eines originalen Fundstücks
              <br />
              (Foto: Astrid Mendes)
            </label>
          </div>
          <div className="referenceTextDiv">
            <h4 className="referenceH4">Geschichte und Bedeutung</h4>
            <p className="referenceText">
              Bei dem Bauwerk handelt es sich um die ehemalige Kirche eines
              Eremiten-Augustinerklosters. Der Baldachinhochaltar und die
              Skulpturen sind vermutlich nach Entwürfen von Johann Peter Wagner
              1773–1779 gearbeitet. Die Kreuzabnahme in der Mittelnische folgt
              dem Motiv des Altarbildes von Peter Paul Rubens, das er 1611 für
              die Liebfrauenkathedrale in Antwerpen schuf. <br /> Der Altar
              zeigt im Aufbau den Typus eines Baldachinaltars mit
              halbkreisförmigem Grundriss. Bis zur Bekrönung besteht der Altar
              aus Stuckmarmor; nur der Aufsatz ist aus Holz. Die Figuren und der
              Zierrat sind in Stuck gearbeitet.
            </p>

            <h4 className="referenceH4">Erhaltungszustand / Befundsituation</h4>
            <p className="referenceText">
              Die ehemalige Polimentvergoldung wurde in einer früheren
              Restaurierungsphase mit einer Bronzierung überstrichen. Die
              originale Polierweißfassung hat in den unteren Zonen zweimal
              überarbeitet und zuletzt 1956 mit einem Alkydharzlack versehen.
              Nur noch in den oberen Bereichen hat sich die originale, gut
              freizulegende Polierweißfassung erhalten.
            </p>

            <h4 className="referenceH4">Restauratorische Zielstellung</h4>
            <p className="referenceText">
              Konservierung der darunter liegenden originalen Fassung und
              Vergoldung. Abnahme der Alkydharzlackfarbe von 1956, die sich auf
              einer weiteren Übermalung befand. Darunter waren nur noch
              Fragmente der ehemaligen Polierweißfassung erhalten.
            </p>

            <h4 className="referenceH4">Maßnahmen</h4>
            <p className="referenceText">
              Es wurde ein Kreidegrund aufgetragen, über dem eine mehrschichtige
              Polierweißfassung erfolgte. Bei dem sanften Glanzgrad der Politur
              am Korpus Christi orientierte man sich an der Gottvatergruppe im
              Auszug. Die Polierweißfassung war bei dieser Gruppe noch erhalten
              und freilegbar. Zusätzlich wurden die Polierspuren im Labor
              vermessen, um diese so originalgetreu wie möglich auszuführen.
              <br />
              Der originale Vergoldungsduktus wurde nach dem Vorbild einer
              abgebrochenen Flügelspitze, die man an einer geschützten Stelle
              hinter dem Altar fand an den Puttoflügeln und Blütengehängen
              nachempfunden. Zudem befand sich auf den gelben Polimentflächen
              der floralen Gehänge eine rote Lasur, die dazu diente, das Gold zu
              akzentuieren. Die Pigmentzusammensetzung und das Bindemittel
              dieses roten Farbtons wurden durch eine naturwissenschaftliche
              Analyse von Dr. Elisabeth Jägers nachgewiesen und an den
              Blütengehängen umgesetzt.
            </p>

            <h4 className="referenceH4">Leitung des Restaurierungsteam</h4>
            <p className="referenceText">
              Dipl.-Restauratorin Inken Stößel und Brigitte Schön M.A.
            </p>

            <h4 className="referenceH4">Fachliche Betreuung</h4>
            <p className="referenceText">Dr. Elisabeth Jägers, Köln.</p>

            <h4 className="referenceH4">Auftraggeber</h4>
            <p className="referenceText">Erzdiözese Mainz.</p>

            <h4 className="referenceH4">Fachbehörden</h4>
            <p className="referenceText">
              Landesdenkmalamt Rheinland-Pfalz Restaurator Reinhold Elenz.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
